let scrollingText = document.querySelector('[js-scrolling-text]');

if (scrollingText != null) {
    let scrollingTextPos = scrollingText.offsetTop;
    let scrollingTextHeight = scrollingText.offsetHeight;

    window.onscroll = function () {
        let currentBottom = window.scrollY + window.innerHeight;
        if (currentBottom > scrollingTextPos) {
            let ratio = (currentBottom - scrollingTextPos) / (window.innerHeight + scrollingTextHeight);
            if (window.innerWidth > 959) {
                ratio = ratio / 2;
            }
            scrollingText.style.transform = 'translateX(-'+(ratio * 100) + '%)';
        }
    };
}
